// $platform-name: 'theperthbloodinstitutelimited';

//
// Environment
//

//
// Config values
//
$platform-name: "theperthbloodinstitutelimited";
$assets-path: "../assets/";

//
// Colours
//

$brand-secondary: #b61c2a;
$grey: #58595b;
$donate-colour: $brand-secondary;
$bg: #ece9e9;
$blue: #1c2ab6;
$pink: #e56381;
$orange: #fc6c60;
$brand-primary: $orange;
$burgundy: #7d0e18;
$darkorange: #fb3e2e;
$mossgreen: #4D9B98 ;

$_subsite-ta:#4D9B98;
$_subsite-tap: #6CB2B2;

// Create greyscale

// Utility colours

//
// Layout
//

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
$site-bleed: 20px;
$container-max-width: 1230px;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
// $logo: "logo.svg";
$logo: "pbi_logo.svg";
$logo-width: 360px;
$logo-height: 120px;

//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$headings-font-family: "Roboto", sans-serif;

$headings-colour: #58595b;
$font-weight-bold: 400;

$font-size-h1: 1.75em;
$font-size-h2: 1.5em;
$font-size-h3: 1.25em;
$font-size-h4: 1.125em;
$font-size-h5: 1.0625em;
$font-size-h6: 1.0625em;
// Links

// Icon font

//
// Buttons
//
$border-radius: 0px 10px 0px 10px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1em;
$btn-padding-y: 0.75em;
$btn-font-family: $font-open-sans;
$btn-font-weight: $font-weight-bold;
$btn-text-colour: white;
// Donate button
$btn-text-transform: uppercase;
$donate-btn-colour: white;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: #989898;
$social-icons-colour: #fff;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific
$social-icons-header-background-colour: $white;

// Footer specific social icons
$social-icons-footer-background-colour: white;
$social-icons-footer-colour: $brand-secondary;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-secondary;
$card-hover-heading-colour: white;
$card-border: 0;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: true;
$menu-admin-max-width: $container-max-width;
$menu-admin-max-width: 100%;
$menu-admin-background-colour: #F0F0F0;
$menu-admin-contents-max-width: $container-max-width;
$menu-admin-font-size: $font-size-small;
$menu-admin-align-items: left;
$menu-admin-link-colour: $body-colour;
$menu-admin-absolute: true;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;
$menu-admin-to-nav-breakpoint: 0;

// Header
//

// Header content

$header-content-max-width: $container-max-width !default; // 100%
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer * .5;
$header-content-padding-bottom: $spacer * .5;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline

// Main call to action
$cta-btn-gap: 5px;
$cta-btn-colour: white;
$cta-btn-breakpoint: 680px;

// Search
$header-search-margin-right: 5px;
$header-search-enabled: false;
// Search - input
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-input-margin-right: 0px;

// Search - button
$header-search-button-background-colour: #7d0e18;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons
$header-social-icons-margin-right: 15px;

//
// Sticky header
//

//
// Navigation
//
$nav-background-colour: transparent;
$nav-normal-align-items: center;
// Top level items
$nav-top-level-item-padding: 15px 12.5px;
$nav-top-level-item-colour: $grey;
// Submenus
$nav-submenu-border-radius: 0;
$nav-submenu-item-colour: $grey;
// Burger button

// Nav normal
$nav-normal-margin-bottom: 0rem;

$nav-normal-mobile-top-level-item-colour: white;
$nav-normal-mobile-submenu-item-colour: white;
$nav-normal-mobile-chevrons-colour: white;
$burger-btn-background-colour: $burgundy;
$nav-normal-border-radius: 0;
$burger-btn-bar-breakpoint: 0;
//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//

// $carousel-max-width: 100%;
// $carousel-border-radius: 0;
// $carousel-details-max-width: 500px;
// $carousel-details-min-height: 100%;
// $carousel-details-padding: 3rem;
// $carousel-details-position-y: center;
// $carousel-details-background-colour: rgba($brand-primary, 1);

// $carousel-read-more-background-colour: $brand-secondary;

// $carousel-controls-button-background-colour: $carousel-details-background-colour;
// $carousel-details-border-radius: 0;
// $carousel-details-box-shadow: none;
// $carousel-details-padding: 9rem;
// $carousel-heading-colour: #fff;
// $carousel-summary-colour: #fff;

// $carousel-controls-position-y: center;
// $carousel-controls-position-x: split;
// $carousel-controls-margin-x: $site-bleed;
// $carousel-controls-icon-colour: #fff;

$carousel-border-radius: 0;
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: linear-gradient(
  to right,
  #b61c2a 0%,
  #fc6c60 15%,
  #e56381 25%,
  rgba(229, 99, 129, 0) 50%
);

$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 600px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;
$carousel-details-padding: 4rem;
// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: #0000007a;
// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: 230px;

//
// Home intro
//
$home-intro-max-width: 980px;
$home-intro-background-colour: white;
$home-intro-font-size: 1.5rem;
$home-intro-padding-y: $spacer * 1;
$home-intro-margin-top: 3rem;
$home-intro-font-family: 'Nanami Rounded W01 Thin', sans-serif;

//
// Home features
//
$home-features-padding-top: $spacer * 2;
//
// Impact stats
//
$impact-stats-padding-y: $spacer * 4;
$impact-stats-text-align: left;
$impact-stats-background-image: "impact.jpg";
// $impact-stats-background-colour: $bg;
$impact-stats-background-image-opacity: 0.2;

$impact-stats-background-colour: #383838;
$impact-stats-heading-margin-bottom: 1em;
// Heading
$impact-stats-heading-text-align: center;
// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
$impact-stats-figure-font-size: 2.375rem;
$impact-stats-figure-colour: white;
// Summary
$impact-stats-summary-font-size: 1em;
//
// Home feeds
//
$home-feeds-background-colour: $bg;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 4;
$footer-prefab: 5;
$footer-padding-y: $spacer * 3;
// $footer-background-colour: rgba(#eceeef, .3);
$footer-background-colour: $brand-primary;
$footer-colour: white;
$footer-link-colour: white;
$footer-headings-font-size: 1.5em;
// Footer admin links
// Newsletter
$newsletter-background-colour: transparent;
$newsletter-button-background-colour: white;
$newsletter-button-colour: $brand-secondary;
$newsletter-heading-text-align: left;
$newsletter-heading-font-size: 1.5em;
$newsletter-use-placeholders: true;
$newsletter-heading-colour: white;
$newsletter-colour: white;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//
$blockquote-colour: white;
$blockquote-background-colour: #FC6C60;
//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//
$quick-giving-background-colour: $orange;
$home-quick-giving-donation-amount-border-colour: white;

//
// Home quick giving panel
//
$home-quick-giving-padding-y: $spacer * 3;
$home-quick-giving-colour: white;
$home-quick-giving-heading-colour: white;
$home-quick-giving-donation-amount-background-colour: transparent;
$home-quick-giving-donation-amount-figure-colour: white;
$home-quick-giving-donate-btn-background-colour: white;
$home-quick-giving-donate-btn-colour: $brand-secondary;
$home-quick-giving-donate-btn-border-colour: white;
// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

// Subsite
//

$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: true;
$subsite-hide-header-search: true;
$subsite-hide-main-cta: false;
// $subsite-content-max-width: $container-max-width;
// $subsite-title-font-size: $page-title-font-size;

// // Subsite logo
$subsite-logo: 'title';
// $subsite-logo-retina: $subsite-logo !default;
// $subsite-logo-width: 120px !default;
// $subsite-logo-height: 120px !default;
// $subsite-logo-margin: $header-content-margin-top 0 0 !default;

// // Subsite nav
// $subsite-nav-breakpoint: $nav-breakpoint;
$subsite-nav-max-width: 100%;
$subsite-nav-margin-top: 0;
$subsite-nav-margin-bottom: 0;
$subsite-nav-background-colour: lighten(#4d9b98, 15%);
$subsite-nav-contents-max-width: $container-max-width;
$subsite-nav-align-items: left;
$subsite-nav-top-level-item-padding: 10px 20px;
$subsite-nav-top-level-item-colour: $white;
$subsite-nav-top-level-item-font-size: $font-size-base;
$subsite-nav-top-level-item-font-weight: 400;
$subsite-nav-top-level-item-background-colour: transparent;
$subsite-nav-top-level-item-hover-colour: $white;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour !default;

// $subsite-nav-submenu-width: $nav-submenu-width !default;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour !default;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow !default;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius !default;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding !default;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour !default;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family !default;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size !default;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight !default;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour !default;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour !default;

// Nav - Parent nav items clickable
$nav-parent-clickable-enabled: true;