.menuMain .topLevel {
  border-top: solid 1px #e0e0e0;
}

// Header CTA + Logo - Mobile
@media (max-width: 576px) {
  .headerContent .mainCallToAction a.cta-button.donate {
    width: 82.14px;
  }

  .headerContent .mainCallToAction {
    flex: 1 !important;
  }
}

//intro
@media (max-width: map-get($breakpoints, md)) {
  .homeIntroWrapper {
    max-width: calc(100% - 40px);
  }
}

//impact icons on the side
.homeFeatureSnippets {
  tr {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    flex: 1;
    justify-content: center;
  }

  td {
    width: auto !important;
    align-items: flex-start;

    &:first-of-type {
      margin-right: 20px;
    }

    p {
      margin: 0 0 1em 0;
      font-size: 16px;
      font-size: 1em;
    }

    h2,
    h3,
    h4 {
      font-size: 2.375rem;
      line-height: 1;
      margin-bottom: 5px;
      color: white;
      font-weight: 800;
    }

    img {
      max-height: 68px;
      max-width: 68px;
    }
  }
}

@media (max-width: map-get($breakpoints, md)) {
  .homeFeatureSnippets {
    tr {
      flex-direction: column;
      text-align: center;
      flex: inherit;
    }

    td {
      &:first-of-type {
        margin-right: 0px;
      }
    }
  }
}

//quick
.homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount .donationAmountFigure {
  border: solid white 2px;
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .homefeaturecategory-homeboxquickgiving h2 {
    margin-right: $spacer;
  }
}

.homefeaturecategory-homeboxquickgiving,
.pageFooterWrapper,
.SupportusFeed .feedList {
  background: rgb(182, 28, 42);
  background: linear-gradient(90deg,
      rgba(182, 28, 42, 1) 0%,
      rgba(252, 108, 96, 1) 50%,
      rgba(229, 99, 129, 1) 100%);
}

body.subsite.thrombosis-australia .subsiteFeeds .subsiteFeed.SupportusFeed .feedList .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
  background-color: transparent !important;

  h2 {
    color: #fff;
  }

  .donationAmount {
    color: #fff;
    background-color: transparent;
  }

  .donationSelected {
    color: $headings-colour;
    background-color: #fff;
  }
}

//footer
.footerBox.footerBox4 {
  border-top: 1px solid rgba(white, 0.3);
  font-size: 12px;
  margin-top: 60px;

  p {
    padding: 20px 0 0;
    float: left;
  }

  img {
    padding: 20px;
  }
}

.footerBox1 {
  padding-right: 6rem;
}

.socialIcons .email:before {
  content: "\f003";
}

.mainCallToAction .socialIcons .facebook,
.shareList a#facebookLink {
  background-color: $brand-secondary;
}

.shareList a#twitterLink {
  background-color: #e22f69;
}

.mainCallToAction .socialIcons .instagram {
  background-color: #e22f69;
}

.mainCallToAction .socialIcons .email,
.shareList a#emailLink {
  background-color: $orange;
}

.mainCallToAction .socialIcons .linkedin {
  background-color: $pink;
}

//carousel
.carouselSlideReadMore {
  background-color: transparent;
  border: solid white 1px;
}

.carouselSlideDetail {
  overflow: hidden;
  position: relative;

  .carouselSlideHeading {
    z-index: 1;
  }

  .carouselSlideSummary {
    z-index: 1;
  }
}

.carouselSlideTitle {
  &:before {
    content: "";
    background-image: url(../assets/blood_fade.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    background-size: 40%;
    left: 0;
    opacity: 0.2;
    z-index: 1;
    background-position: left;
  }
}

.carouselDetailWrapper {
  z-index: 1;
}

.carouselImageMultiple .carouselDetailWrapper {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: map-get($breakpoints, lg)) {
  .carouselSlideDetail {
    border-radius: 0;
  }

  .carouselSlideHeading a {
    color: white;
  }

  .carouselSlideSummary {
    color: white;
  }
}

//intro

//Changing the "current appeals" background colour tab

.tab.active a {
  color: white;
}

@media (max-width: map-get($breakpoints, lg)) {
  .carouselSlideTitle:before {
    content: none;
  }

  .carouselSlideDetail {
    background: rgb(182, 28, 42);
    background: linear-gradient(90deg,
        rgba(182, 28, 42, 1) 0%,
        rgba(252, 108, 96, 1) 50%,
        rgba(229, 99, 129, 1) 100%);
  }

  .mainCallToAction .socialIcons .instagram,
  .mainCallToAction .socialIcons .linkedin,
  .mainCallToAction .socialIcons .email,
  // .shareList a#emailLink, // JK - This causes a bug: https://www.bugherd.com/projects/179933/tasks/40
  // .shareList a#facebookLink
  .mainCallToAction .socialIcons .facebook {
    background-color: white;
  }

  .menuMain .mainCallToAction .socialIcons a:before {
    color: $brand-secondary;
  }
}

// Search
@media (min-width: 1025px) {
  .mainCallToAction .socialIcons .search {
    background-color: $burgundy;
  }
}

// // Thrombosis subsite button header
// .mainCallToAction a.thrombosis {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0 15px 0 0;
//   padding: 10px;
//   border-radius: 0 10px 0 10px;
//   border: 1px solid #eee;

//   img {
//     max-height: 30px;
//   }
// }

// .menuMain .mainCallToAction a.thrombosis {
//   display: none;
//   background-color: $white;
//   margin: 0 auto 0 20px;
// }

@media (max-width: 768px) {
  .headerContent .mainCallToAction a.thrombosis {
    display: none;
  }

  .menuMain .mainCallToAction a.thrombosis {
    display: flex;
  }
}

//client styles
.burgundy { color: $burgundy; }
.red { color: $brand-secondary; }
.lightred { color: #e22f69; }
.pink { color: $pink; }
.lightpink { color: $orange; }
.mossgreen { color: $mossgreen; }


.customForm .checkboxRadioGrid .answerOptions {
  grid-template-columns: repeat(2, 1fr)
}

body.PostCategory_post-no-sidebar .postAside {
  display: none;
}

body.PostCategory_post-no-sidebar .contentBlockWrapper .contentBlock {
  display: block;
}

@media (min-width: map-get($breakpoints, lg) + 1px) {
  .menuMain .mainCallToAction {
    display: none;
  }
}


// Carousel appeal post - Buttons
.carouselSlideDetail.appeal {

  .carouselSlideReadMore,
  .readMore {
    display: table;
  }

  #totaliserActions {
    display: none;
  }
}


// Header + Site switcher - Layout
a.mainLogo {
  width: 410px;
  height: 130px;
  background-color: $white;
  background-size: contain;
  background-size: calc(100% - 75px) auto;
  background-position: left 45% bottom 10px;
  @media (max-width: map-get($breakpoints, lg)) {
    width: 360px;
    height: 100px;
  }
  @media (max-width: 680px) {
    width: 170px;
    height: 55px;
    background-size: contain;
    background-position: center center;
    max-width: 100%;
  }
}

@media (min-width: #{680px + 1px}) {
  .pageHeader .headerContent {
    align-items: flex-end;
    padding-top: 0;
    padding-bottom: $spacer;
  }
}

@media (max-width: 680px) {
  .pageHeader .headerContent {
    padding-left: #{$site-bleed / 2};
    padding-right: #{$site-bleed / 2};
  }
}
.pageHeader .headerContent .mainCallToAction {
  margin-top: 0;
  margin-bottom: 0;
}


// Site switcher
.siteSwitcher { display: none; }
.menuAdminContainer {
  @media (max-width: 680px) {
    position: relative;
    background-color: $white;
  }
  #menuAdmin {
    padding-left: calc(410px + #{$header-content-padding-left});
    @media (max-width: map-get($breakpoints, lg)) {
      padding-left: calc(360px + #{$header-content-padding-left});
    }
    @media (max-width: 680px) {
      padding-left: 0;
      padding-right: 0;
      justify-content: flex-end;
    }
    li {
      margin: 0;
      padding: 0;
    }

    a {
      margin: 0;
      border: .75px solid #ececec;
      background-color: transparent;
      font-size: 0;
      display: block;
      background-position: center center;
      background-size: auto 70%;
      background-repeat: no-repeat;
      width: 195px;
      height: 70px;
      text-decoration: none;
      transition: 300ms all ease;
      filter: grayscale(.75);

      @media (max-width: map-get($breakpoints, lg)) {
        width: 130px;
        height: 45px;
      }
      @media (max-width: 680px) {
        height: 40px;
        width: 110px;
        background-color: #F0F0F0;
      }

      &[href="/"] { background-image: url($assets-path + "logo-small-pbi.png"); }
      &[href="/thrombosis-australia-professionals"] { background-image: url($assets-path + "logo-tba.png"); }
      &[href="/thrombosis-australia"] { background-image: url($assets-path + "logo-small-tb.png"); }

      &:hover {
        transition: 300ms all ease;
        filter: grayscale(0);
        background-color: $white;
      }
    }
    
    // Hide basket
    .menuAdminBasket { display: none; }
  }
}

// Site switcher - Show when needed
body:not(.subsite) #menuAdmin a[href="/"] { display: none; }
body.subsite.thrombosis-australia-professionals #menuAdmin a[href="/thrombosis-australia-professionals"] { display: none; }
body.subsite.thrombosis-australia #menuAdmin a[href="/thrombosis-australia"] { display: none; }


// Remove from header maincalltoaction
// .headerContent .mainCallToAction ul.siteSwitcher {
//   display: none !important;
// }

// Site switcher - Layout
// ul.siteSwitcher {
//   position: relative;
//   width: 100%;
//   background-color: #F6F6F6;
//   width: auto;
//   display: flex !important;
//   align-items: center;
//   justify-content: flex-start;
//   list-style: none;
//   margin: 0;
//   padding-inline-start: 0;
//   padding: 0 0 0 410.5px;

//   li {
//     padding: 0;
//     margin: 0;
//   }

  // a {
  //   margin: 0;
  //   border: .75px solid #ececec;
  //   background-color: transparent;
  //   font-size: 0;
  //   display: block;
  //   background-position: center center;
  //   background-size: auto 70%;
  //   background-repeat: no-repeat;
  //   width: 195px;
  //   height: 70px;
  //   text-decoration: none;
  //   transition: 300ms all ease;
  //   filter: grayscale(.75);

  //   @media (max-width: map-get($breakpoints, sm)) {
  //     width: 130px;
  //     height: 45px;
  //   }

  //   &.pbi { background-image: url($assets-path + "logo-small-pbi.png"); }
  //   &.tap { background-image: url($assets-path + "logo-tba.png"); }
  //   &.ta { background-image: url($assets-path + "logo-small-tb.png"); }

  //   &:hover {
  //     transition: 300ms all ease;
  //     filter: grayscale(0);
  //     background-color: $white;
  //   }
  // }
// }

// #menuAdmin {
//   margin-block-start: 0;
//   margin-block-end: 0;
//   padding: 0 $site-bleed;

//   .menuAdminBasket { display: none; }

//   li {
//     padding: 0;
//     margin: 0;
//   }
// }

// Site switcher - Responsiveness
// @media (max-width: map-get($breakpoints, lg)) {
//   .menuMain .mainCallToAction ul.siteSwitcher {
//     display: flex;
//     padding: 10px 20px;
//     background-color: transparent;

//     a {
//       border-radius: 0 10px 0 10px;
//       background-color: $white;
//       filter: grayscale(0);
//       margin-right: 10px;
//     }
//   }
// }

// Site switcher - Show when needed
body:not(.subsite) ul.siteSwitcher .pbi { display: none; }
body.subsite.thrombosis-australia-professionals ul.siteSwitcher .tap { display: none; }
body.subsite.thrombosis-australia ul.siteSwitcher .ta { display: none; }


// Subsite tweaks
body.subsite {

  // Homepage element position
  .contentContainer {
    .headerWrapperSubsite { order: 1 !important; }
    .headerTextSubsite { order: 2 !important; }
    .subsiteBody { order: 3 !important; }
    .subsiteFeedsWrapper { order: 4 !important; }
  }

  @mixin colour-focused ($_subsite-colour) {

    .breadcrumbWrapper a { color: $_subsite-colour; }

    // Title
    .headerTextSubsite {
      width: 100%;
      max-width: 100%;
      padding: 20px 0;
      background-color: $_subsite-colour;
      margin: 0;
    }

    h1.subsiteTitle {
      width: 100%;
      max-width: $container-max-width;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto;
      color: $white;
      font-weight: bold;
      font-size: 2.5em;
    }


    // Nav fix
    @media (min-width: 1025px) {
      .menuMain .mainCallToAction {
        display: none;
      }
    }


    // Button setup
    .postContent a.cta-button,
    a.button.backToTop,
    .mainCallToAction .cta-button:not([class*="donate"]) {
      background-color: $_subsite-colour;
      &:hover { background-color: darken($_subsite-colour, 10%); }
    }

    // Header Social Icons - Mail
    .mainCallToAction ul.socialIcons a.email {
      background-color: $_subsite-colour;
      &:hover { background-color: darken($_subsite-colour, 10%); }

      @media (max-width: map-get($breakpoints, lg)) {
        background-color: $white;
        &:hover { background-color: darken($white, 10%); }
      }
    }

    .listedPost:hover .listedPostText {
      background-color: $_subsite-colour !important;
    }

    // Home Feeds
    .homeFeedsWrapper {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    // Feed specific
    .homeFeed { margin-right: 0; }

    // Feeds Title
    .feedsTitle {
      text-align: center;
      font-size: 2rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    // Element colours
    .groupSignUpBtn,
    .pageFooter .socialIcons a:before,
    .associated:not(.associatedBox) a {
      color: $_subsite-colour;
    }

    // Element backgrounds
    .pageFooterWrapper {
      background: $_subsite-colour;
    }

    // Element background colours
    .shareList a,
    .subsiteBody .cta-button {
      background-color: $_subsite-colour !important;

      &:hover {
        background-color: darken($_subsite-colour, 10%) !important;
      }
    }

    &.subsiteInner h1.title {
      color: $_subsite-colour;
      font-weight: bold;
      font-size: 2em;
    }


    // Subsite quick giving - colours
    .quickGivingPanel {
      background-color: #F0F0F0;

      .button.donateBtn {
        background-color: #fff;
        color: rgb(182, 28, 42);
        font-weight: bold;
      }
    }

    .quickGivingPanel .formQuestion.donationAmount {
      background-color: #F0F0F0;
      color: $_subsite-colour;
    }

    .quickGivingPanel .formQuestion.donationAmount.donationSelected {
      color: #FFF;
      background-color: darken($_subsite-colour, 12.5%);
    }

    .subsiteFeedsnippets .feedItem:hover .feedItemDetailsWrapper {
      background-color: #FFF !important;
    }

    // Subsite quick giving - layout in feeds
    .subsiteFeedsnippets .feedItem .feedItemDetailsWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $spacer * 2;

      > * { margin: 0 !important; }

      .quickGivingPanel {
        background-color: transparent;
        padding: 0;
        margin: 0 0 0 20px;

        .selectDonationAmount {
          margin: 0;
          a.button.donateBtn {
            display: inline-flex;
            height: 43px;
            margin: 4px;
          }
        }
      }

      @media (max-width: map-get($breakpoints, lg)) {
        flex-direction: column;
        .quickGivingPanel {
          margin: $spacer 0 0 0;
        }
      }
    }


    // Blockquotes - subsite brand
    blockquote.mossgreen {
      background-color: transparent;
      box-shadow: 0 0 0 3px $_subsite-colour;
      color: $_subsite-colour;
    }

    blockquote.alternative.mossgreen {
      background-color: $_subsite-colour;
      color: $white;
    }

    // Header underline
    .homeFeed .feedsTitle {
      text-align: center;

      &:after {
        content: '';
        border-bottom: 5px solid $_subsite-colour;
        display: block;
        margin: auto;
        margin-top: 15px;
        max-width: 190px;
      }
    }
  }

  // Subsite specific - colour
  &.thrombosis-australia-professionals {
    @include colour-focused($_subsite-colour: $_subsite-tap);
    a.mainLogo { background-image: url($assets-path + "logo-tba.png"); }
  }

  &.thrombosis-australia {
    @include colour-focused($_subsite-colour: $_subsite-ta);
    a.mainLogo { background-image: url($assets-path + "logo-tb.png"); }
  }
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount .donationAmountFigure,
.quickGivingPanel .formQuestion.donationAmount .donationAmountFigure {
  border: 3px solid white;
  border-radius: 0 10px 0 10px;
}

.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn {
  font-weight: bold;
}

// More subsite bespoke
body.thrombosis-australia {
  .homeFeeds {
    max-width: 100%;
    flex-direction: column;
  }
  section.homeFeed:not(.SupportusFeed) {
    max-width: $container-max-width;
    margin: auto;
  }
}

body:not(.subsite) {
  .mainCallToAction .subsiteSocialIcons {
    display: none;
  }
}

body.thrombosis-australia,
body.thrombosis-australia-professionals {
  .headerTextSubsite {
    display: none;
  }
  .mainCallToAction .socialIcons:not(.subsiteSocialIcons) {
    display: none;
  }

  section.SupportusFeed {
    max-width: 100%;
    .feedList {
      padding: 2rem 0;
    }
  }
}

// DES-5470
.whyDonate {
  display: none;
}

// DES-5495
body:not(.subsite) {
  .subsiteButton {
    display: none !important;
  }
}

body:not(.thrombosis-australia-professionals) {
  .subsiteButton.thrombosis-australia-professionals {
    display: none;
  }
}

body.thrombosis-australia-professionals {
  .mainCallToAction .cta-button:not(.thrombosis-australia-professionals) {
    display: none;
  }

  .mainCallToAction .cta-button[href*="donate"] {
    display: inline-flex;
    background-color: #FC6C60 !important;
  }

  #pageHeader > div.content.headerContent > div > div > a:nth-child(4) {
    background-color: $_subsite-tap !important;
  }
}

.cta-button.thrombosis-australia-professionals {
  &:first-of-type {
    background-color: #FC6C60 !important;
  }
  &:not(:first-of-type) {
    background-color: #EE3343 !important;
  }
}